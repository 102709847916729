import Typed from 'typed.js';

document.addEventListener("turbolinks:load", () =>{
  var typed = new Typed('.at_once1', {
    strings: ['Qualität.'],
    smartBackspace: true, // Default value
    showCursor: false,
    startDelay: 0,
    cursorChar: '_',
    autoInsertCss: true,
    typeSpeed: 50,
    backSpeed: 50,
    backDelay: 0,
    loop: false,
    loopCount: false,
  });
});

document.addEventListener("turbolinks:load", () =>{
  var typed = new Typed('.at_once2', {
    strings: ['Sicherheit.'],
    smartBackspace: true, // Default value
    showCursor: false,
    startDelay: 700,
    cursorChar: '_',
    autoInsertCss: true,
    typeSpeed: 50,
    backSpeed: 50,
    backDelay: 0,
    loop: false,
    loopCount: false,
  });
});

document.addEventListener("turbolinks:load", () =>{
  var typed = new Typed('.at_once3', {
    strings: ['Künstliche Intelligenz.'],
    smartBackspace: true, // Default value
    showCursor: true,
    startDelay: 1200,
    cursorChar: '_',
    autoInsertCss: true,
    typeSpeed: 50,
    backSpeed: 50,
    backDelay: 0,
    loop: false,
    loopCount: false,
  });
});